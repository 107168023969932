import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class TokenDataService {

  constructor(private router: Router) { }

  getToken() {
    return localStorage.getItem('acsTkn');
  }

  removeAndNavg() {
    this.remData();
    this.router.navigate(['/auth/login']);
  }

  remData() {
    localStorage.clear(); //remove local
  }

  setToken(tkn: any) {
    localStorage.setItem("acsTkn", tkn);
  }

  setUser(res: any) {
    let user: any = {
      id: res.userId,
      eId: res.entityId,
      roleId: res.roleId,
      name: res.fullName
    };
    localStorage.setItem("usr", JSON.stringify(user));
  }

  getUser() {
    let usr: any = localStorage.getItem('usr');;
    try {
      if (usr && JSON.parse(usr))
        return JSON.parse(usr) || null;
      else
        return null;
    } catch (ex) {
      return null;
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenDataService } from '../services/token-data.service';
import { environment } from '../../../environments/environment';
@Injectable()

export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenDataServ: TokenDataService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('googleapis.com')) {
      // google apis
      //no need of anything
    } else {
      let url = `${environment.API_ENDPOINT}${request.url}`;
      if (request.url.includes('LoginCheck and so on')) {
        request = request.clone({
          url
        });
      } else {
        request = request.clone({
          setHeaders: {
            "Authorization": "Bearer " + (localStorage.getItem('acsTkn') || ''),
            "ClientId": localStorage.getItem('cId') || ''
          },
          url
        });
      }
      request = request.clone({
        headers: request.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
      });
    }
    return next.handle(request);
  }
}

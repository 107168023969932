export class HttpHelper {

  public static errMessage(err: any): string {
    if (err && err.error) {
      if (err.error.isError) {
        if (Array.isArray(err.error.responseException) && err.error.responseException.length > 0 && err.error.responseException[0] != '')
          return this.chkMsg(err.error.responseException);
        else if (err.error.Messages && typeof (err.error.Messages) === 'string')
          return err.error.Messages;
        else if (err.error.responseException && typeof (err.error.responseException) === 'string')
          return err.error.responseException;
      } else if (err.error.error && typeof (err.error.error) === 'string')
        return err.error.error;
    }
    return 'Please try after some time';
  }

  public static getBasePath(): string {
    return window.location.protocol + "//" + window.location.hostname;
  }

  public static redirectToUrl(url: any): any {
    if (url)
      window.location = url;
    else
      return '/home/dashboard';
  }

  public static chkMsg(msgs: any) {
    let msg: string = '';
    for (let k = 0; k < msgs.length; k++) {
      msg += msgs[k];
      if (msgs.length - 1 > k)
        msg += ' ';
    }
    return msg;
  }

  public static otherMsg(data: any) {
    if (data && data.message)
      return data.message;
    return '';
  }

}

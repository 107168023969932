import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenDataService } from '../services/token-data.service';
import { HttpHelper } from '../services/http-helper';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    rout: any;
    constructor(
        router: Router, private tokenDataServ: TokenDataService,
        private toastr: ToastrService
    ) {
        this.rout = router;
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return this.errorHandler(error)
                })
            )
    }

    errorHandler(error: HttpErrorResponse) {
        if (error.status == 401) {
            this.tokenDataServ.removeAndNavg();
        }
        // let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            // console.log("client side");
            // errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            // console.log("server side");
            // errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;

            this.toastr.error(HttpHelper.errMessage(error));
        }
        return throwError(error);
    }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UrlSerializer } from '@angular/router';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { OfflineInterceptor } from './shared/interceptors/offline.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';

import { ToastrModule } from 'ngx-toastr';

import { AuthGuard } from './shared/guard/auth.guard';
import { TokenDataService } from './shared/services/token-data.service';
import { ProgressBarModule } from './progress-bar/progress-bar.module';
import { CustomUrlSerializer } from './custom-url-serializer';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot({ maxOpened: 3 }), // ToastrModule added
    ProgressBarModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    AuthGuard,
    TokenDataService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
